html,
body {
  height: 100%;
}
body {
  margin: 0;
  overflow: hidden;
}
a:focus,
button:focus,
input:focus,
textarea:focus {
  outline: none;
}

button {
  -ms-user-select: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}
/** Scrollable **/
$bg-color-scroll: #aab2bf;
$bg-color-scroll-2: #d9d9d9;
$border-radius-scroll: 4px;
$width-scroll: 5px;
$height-scroll: 5px;

@mixin scrollbar-input($width-input, $height-input, $radius-input) {
  &::-webkit-scrollbar {
    width: $width-input;
    height: $height-input;
  }
  &::-webkit-scrollbar-thumb {
    background: $bg-color-scroll;
    border-radius: $radius-input;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 $radius-input $bg-color-scroll-2;
    border-radius: $radius-input;
  }
  scrollbar-color: $bg-color-scroll $bg-color-scroll-2;
  scrollbar-width: thin;
}
.custom-scrollbar-default {
  @include scrollbar-input(5px, 5px, 4px);
  overflow: hidden;
  &:hover {
    overflow: overlay;
    overflow: scroll;
  }
}
.custom-scrollbar-md {
  @include scrollbar-input(8px, 8px, 8px);
  overflow: overlay;
  overflow: scroll;
}
.custom-scrollbar-pkm {
  @include scrollbar-input(6px, 6px, 4px);
  overflow: hidden;
  &:hover {
    overflow: overlay;
  }
  @-moz-document url-prefix() {
    &:hover {
      overflow: scroll;
    }
  }
}
.custom-scrollbar-pkm-hidden {
  @include scrollbar-input(0, 0, 0);
  overflow: overlay;
}
.border-custom-blue {
  border-color: #1a87d7;
}
.custom-scrollbar-room-plan {
  @include scrollbar-input(6px, 6px, 4px);
  overflow: hidden;
  &:hover {
    overflow: overlay;
  }
  @-moz-document url-prefix() {
    &:hover {
      overflow: scroll;
    }
  }
  padding-right: 0.5rem;
}
/** end scroll **/

// Input Number
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

/** Set height table ant design **/
.ant-table-wrapper {
  height: 100%;

  .ant-spin-nested-loading {
    height: 100%;

    .ant-spin-container {
      height: 100%;
      display: flex;
      flex-flow: column nowrap;

      .ant-table {
        flex: auto;
        overflow: hidden;

        .ant-table-container {
          height: 100%;
          display: flex;
          flex-flow: column nowrap;

          .ant-table-header {
            flex: none;
          }

          .ant-table-body {
            flex: auto;
            overflow: scroll;
          }
        }
      }

      .ant-table-pagination {
        flex: none;
      }
    }
  }
}
/**End set height table ant design **/
/*custom modal*/
.custom-modal-confirm-pkm {
  .ant-modal-content {
    border-radius: 6px;
  }
  .ant-modal-body {
    padding: 22px 22px 14px !important;

    .ant-modal-confirm-body-wrapper {
      .ant-modal-confirm-btns {
        .ant-btn {
          &:first-child {
            color: rgb(247, 67, 82);
            border: 1px solid rgb(247, 67, 82);
          }
          border-radius: 6px;
          height: 35px;
          font-weight: bold;
        }
        .ant-btn-primary {
          background-color: #1a87d7;
        }
      }
    }
  }
}
.custom-bg-gray {
  background-color: #f0f6fb !important;
}
.control-color-blue {
  color: #1a87d7;
  span {
    color: #1a87d7 !important;
    font-weight: bold;
  }
  button:first-child {
    width: 100%;
    padding-left: 1.5rem;
    padding-right: 1rem;
  }
}
.small-checkbox .ant-checkbox-inner {
  width: 14px;
  height: 14px;
}
.front-desk-control {
  height: 40px !important;
  background: #ffffff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1) !important;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  transition: background-color 0.3s;
  .ant-btn {
    border: none;
  }
  .ant-menu-vertical .ant-menu-submenu-title {
    height: inherit;
    line-height: 14px;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    color: #1a87d7;
    padding: 0;
  }
  .ant-menu-vertical {
    border-right: transparent;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-menu-vertical .ant-menu-item,
  .ant-menu-vertical-left .ant-menu-item,
  .ant-menu-vertical-right .ant-menu-item,
  .ant-menu-inline .ant-menu-item,
  .ant-menu-vertical .ant-menu-submenu-title:active,
  .ant-menu-vertical-left .ant-menu-submenu-title:active,
  .ant-menu-vertical-right .ant-menu-submenu-title:active,
  .ant-menu-inline .ant-menu-submenu-title:active {
    background-color: #e7e7e7;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .ant-menu-submenu-arrow {
    display: none;
  }
}
.table-print-folio {
  height: 100%;
  display: table;
  border-collapse: collapse;
  font-size: 10px;
  width: 100%;
  & .cell {
    border: 1px solid black;
    display: table-cell;
    border-right: none;
    border-top: none;
    &:first-child {
      border-left: none;
    }
  }

  /* & thead th {
    border-bottom: 1px solid black;
    font-size: 8px;
    border-left: 1px solid black;
  }
  & thead th:first-child {
    border-left: none;
  }
  & td {
    border-left: 1px solid black;
    font-size: 8px;
    color: black;
    & div {
      display: inline-block;
    }
    &:first-child {
      border-left: none;
    }
  }

  & tbody {
    & tr:first-child {
      border-top: 1px solid black;
    }
  } */
}
.hiden-handler-wrap {
  .ant-input-number-handler-wrap {
    visibility: hidden;
  }
}
.remove-left td:first-child{
    left: 0 !important;
    padding-left: 10% !important;
}
// custom pagination antd
.ant-pagination {
  & li {
    border-radius: 6px;
  }
  & .ant-pagination-prev button {
    border: none;
  }
  & .ant-pagination-next button {
    border: none;
  }
  & .ant-pagination-item {
    border: none;
    color: #00293b;
    & a {
      color: #00293b;
      font-weight: 500;
    }
  }
  & .ant-pagination-item-active {
    border: none;
    background: #ffffff;
    box-shadow: 0px 0px 3px rgb(167, 167, 167);
    & a {
      color: #00293b;
      font-weight: 500;
    }
  }
  & .ant-pagination-options {
    & .ant-select {
      & .ant-select-selector {
        border: none;
        border-radius: 10px;
        background: #f0f2f5;
        color: #00293b;
      }
    }
  }
  & .ant-pagination-options-quick-jumper {
    & input {
      border-radius: 6px;
    }
    & span {
      & button {
        border-radius: 6px;
        background-color: #1a87d7;
        font-weight: 600;
        color: white;
      }
    }
  }
  & .ant-pagination-item-link {
    margin-top: -1px;
  }
}
.custom-submenu {
  & ul {
    @include scrollbar-input(6px, 6px, 4px);
    overflow: hidden;
    &:hover {
      overflow: overlay;
    }
    @-moz-document url-prefix() {
      &:hover {
        overflow: scroll;
      }
    }
    padding-right: 0.5rem;
  }
}
.errors-select {
  & .ant-select-selector {
    border: 1px solid rgb(255, 47, 47) !important;
  }
}
.errors-input {
  border: 1px solid rgb(255, 47, 47) !important;
}
.row-selected {
  background-color: #dcf4ff;
  font-weight: bold;
}
//custom ant notification
.ant-notification-notice.ant-notification-notice-warning.ant-notification-notice-closable {
  padding-left: 8%;
}
.context-room-plan {
  top: 0;
  width: 300px;
  height: 450px;
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: rgb(196, 196, 196) 0px 1px 2px 0px,
    rgb(232, 235, 238) 0px 2px 6px 2px;
  z-index: 1000;
  animation-name: fade-in-context-room;
  animation-fill-mode: both;
  animation-duration: 0.1s;
  & .ant-menu-item {
    height: 30px !important;
    line-height: 30px !important;
  }
}
@keyframes fade-in-context-room {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
